/* Global value object */
export const GlobalValues = {
    // Global configuration
    appName: "Doorkeeper Commercial",
    appNameShort: "VMSC",

    // API Keys
    googleMapsApiKey: "AIzaSyAeYsBx_MLIVrl7pgV2mVuw-N5VrN1N344",

    // Role constants
    superAdministratorRole: {
        id: 1,
        name: "Super Administrator"
    },
    organizationAdministratorRole: {
        id: 2,
        name: "Organization Administrator"
    },
    establishmentAdministratorRole: {
        id: 3,
        name: "Establishment Administrator"
    },
    securityGuardRole: {
        id: 4,
        name: "Security Guard"
    },
    employeeRole: {
        id: 5,
        name: "Employee"
    },
    companyEmployeeRole: {
        id: 6,
        name: "Company Employee"
    },
    companyEmployeeAdminRole: {
        id: 7,
        name: "Company Employee Admin"
    },
    companyEmployeeReceptionistRole: {
        id: 8,
        name: "Company Employee Receptionist"
    },

    // Base URLs
    dashboardBaseUrl: "https://commercial-api.doorkeeper.in/",
    apiBaseUrl: "https://commercial-api.doorkeeper.in/api/",
    // dashboardBaseUrl: "http://localhost:3000/",
    // apiBaseUrl: "http://192.168.24.29:8000/api/",

    // Client URLs
    clientBaseUrl: "https://commercial.doorkeeper.in/",

    // Authentication URLs
    generateOtpUrl: "users/generate-otp/",
    verifyOtpUrl: "users/verify-otp/",
    logoutUrl: "users/custom-logout/",

    // Role URLs
    roleListUrl: "role/list/",

    // NDA URLs
    NDACreateUrl: "nda/",
    NDALinkingUrl: "nda/assign/",
    NDAListUrl: "nda/list/",
    NDALinkedListUrl: "nda/assigned-list/",
    NDAStatusUrl: "nda/add-active-nda/",

    CompanyNDACreateUrl: "company-nda/",
    CompanyNDALinkingUrl: "company-nda/assign/",
    CompanyNDAListUrl: "company-nda/list/",
    CompanyNDALinkedListUrl: "company-nda/assigned-list/",
    CompanyNDAStatusUrl: "company-nda/add-active-nda/",

    // Organization URLs
    organizationCreateUrl: "organization/",
    organizationListUrl: "organization/list/",
    organizationListFilterUrl: "organization/list-filter/",

    // Establishment URLs
    establishmentCreateUrl: "establishment/",
    establishmentListUrl: "establishment/list/",
    establishmentListFilterUrl: "establishment/list-filter/",
    establishmentListFilterForEstablishmentAdminUrl: "establishment/establishment-list-filter-for-establishment-admin/",
    establishmentListForAdminUrl: "establishment/establishment-list-for-admin/",
    establishmentVisitorAnalysis: "establishment/visitor-analysis/",

    // Emergency
    companyEmergencyBroadcastURL: "company/emergency-broadcast/",
    establishmentEmergencyBroadcastURL: "establishment/emergency-broadcast/",

    // Building URLs
    buildingCreateUrl: "building/",
    buildingListUrl: "building/list/",
    buildingListFilterUrl: "building/list-filter/",

    // Unit URLs
    unitCreateUrl: "flat/",
    unitListUrl: "flat/list/",
    unitListFilterUrl: "flat/list-filter/",

    // Service Category URLs
    serviceCategoryCreateUrl: "services/service-category/",
    serviceCategoryListUrl: "services/service-category-list/",
    serviceCategoryListFilterUrl: "services/service-category-list-filter/",

    // Service Sub-Category URLs
    serviceSubCategoryCreateUrl: "services/service-sub-category/",
    serviceSubCategoryListUrl: "services/service-sub-category-list/",
    serviceSubCategoryListFilterUrl: "services/service-sub-category-list-filter/",

    // Service URLs
    serviceCreateUrl: "services/",
    serviceListUrl: "services/service-list/",
    serviceListFilterUrl: "services/service-list-filter/",

    // Service Slot URLs
    serviceSlotCreateUrl: "services/service-slot/",
    serviceSlotListUrl: "services/service-slot-list/",
    serviceSlotListFilterUrl: "services/service-slot-list-filter/",

    // Service Exclusion URLs
    serviceExclusionCreateUrl: "services/service-exclusion/",
    serviceExclusionListUrl: "services/service-exclusion-list/",
    serviceExclusionListFilterUrl: "services/service-exclusion/list-filter/",

    // Service Booking List Organization Admin
    serviceBookingListUrl: "service-booking/service-bookings-list-filter-for-organization-administrator",
    serviceBookingDetailUrl: "service-booking/service-request-detail/",
    serviceBookingUpdateUrl: "service-booking/update-service-request-status-for-organization-administrator/",

    // Employee Category URLs
    employeeCategoryCreateUrl: "employeecategory/",
    employeeCategoryListUrl: "employeecategory/list/",
    employeeCategoryListFilterUrl: "employeecategory/list-filter/",

    // User URLs
    userCreateUrl: "users/",
    userListUrl: "users/list/",
    userListFilterUrl: "users/list-filter/",

    // User Organization URLs
    userOrganizationCreateUrl: "users/organization-administrator-linking/",
    userOrganizationListFilterUrl: "users/organization-administrator-linking-list-filter/",

    // User Establishment URLs
    adminEstablishmentCreateUrl: "users/establishment-admin-linking/",
    adminEstablishmentListFilterUrl: "users/establishment-admin-linking-list-filter/",
    guardEstablishmentCreateUrl: "users/establishment-guard-linking/",
    guardEstablishmentListFilterUrl: "users/establishment-guard-linking-list-filter/",

    // Organization Employee URLs
    employeeCreateUrl: "users/employee-linking/",
    employeeListFilterUrl: "users/employee-linking-list-filter/",

    // Security Guard URLs
    guardCreateUrl: "users/establishment-guard-linking/",
    guardListFilterUrl: "users/establishment-guard-linking-list-filter/",

    // Security Guard Attendance
    establishmentSecurityGuardAttendanceDetailUrl: "attendance/attendance-records-report-detail/",
    establishmentSecurityGuardAttendanceRecordUrl: "attendance/attendance-records-report-list-filter",

    // Company Employee
    companyEmployeeCreateUrl: "users/company-employee-linking/",
    companyEmployeeListFilterUrl: "users/company-employee-linking-list-filter/",

    // Company URLs
    companyCreateUrl: "company/",
    companyListFilterUrl: "company/list-filter/",
    companyListForEstablishmentAdminUrl: "company/list/",
    companyListFilterEmployeeAdminUrl: "company/company-list-filter-for-company-employee-admin/",

    // Company Amenity
    companyAmenityCreateUrl: "company-amenity/",
    companyAmenityListFilterUrl: "company-amenity/list-filter/",

    // Company Amenity Exclusion
    companyAmenityExclusionCreateUrl: "company-amenity/amenity-exclusion/",
    companyAmenityExclusionListFilterUrl: "company-amenity/amenity-exclusion/list-filter/",

    // Company Amenity Slot
    companyAmenitySlotCreateUrl: "company-amenity/amenity-slot/",
    companyAmenitySlotListFilterUrl: "company-amenity/amenity-slot/list-filter/",

    // Company Amenity Booking
    companyAmenityBookingDetailUrl: "company-amenity-booking/amenity-booking-detail/",
    companyAmenityBookingRecordUrl: "company-amenity-booking/amenity-booking-report-list-filter",

    // Company Unit URLs
    companyUnitCreateUrl: "company-unit/company-unit-create/",
    companyUnitListUrl: "company-unit/unit-list/",
    companyUnitDetailsListUrl: "company-unit/company-unit-detail/",

    // Company Employee's Unit URLs
    companyEmployeeUnitCreateUrl: "company-employee/company-employee-units-create/",
    companyEmployeeUnitListUrl: "company-employee/company-unit-list/",
    companyEmployeeUnitDetailsListUrl: "company-employee/company-employee-units-details/",

    // Amenity
    amenityCreateUrl: "amenity/",
    amenityListFilterUrl: "amenity/list-filter/",

    // Amenity Exclusion
    amenityExclusionCreateUrl: "amenity/amenity-exclusion/",
    amenityExclusionListFilterUrl: "amenity/amenity-exclusion/list-filter/",

    // Amenity Slot
    amenitySlotCreateUrl: "amenity/amenity-slot/",
    amenitySlotListFilterUrl: "amenity/amenity-slot/list-filter/",

    // Amenity Booking
    establishmentAmenityBookingDetailUrl: "amenity-booking/amenity-booking-detail/",
    establishmentAmenityBookingRecordUrl: "amenity-booking/amenity-booking-report-list-filter",

    // User Employee Category URLs
    userEmployeeCategoryCreateUrl: "users/user-employee-category/",

    // visitor
    visitorCreateUrl: "visitor/",
    visitorDetailUrl: "visitor/visitor-register-detail",
    visitorUpdateUrl: "visitor/visitor-register-update",
    visitorDetailsUpdatedUrl: "visitor-register-detail-update",
    visitorDetailsNotUpdatedUrl: "visitor-register-detail-noupdate",
    visitorListUrl: "visitor/list-filter",
    companyEmployeeVerify: "company-employee/company-employee-verify",
    companyListGuest: "company/list-for-guest",
    companyListFilterGuest: "company/list-filter-for-guest",
    walkinVisitorCreateUrl: "visitor/walk-in",
    accessoriesListUrl: "visitor/accessory-list",
    verifyPhotograph: "visitor/detect-face",
    establishmenVisitorListUrl:'establishment/visitor-list-filter/',

    // terms & conditions
    fetchTermsAndConditionsUrl: "company-nda/latest/"
};

export const ErrorMessages = {
    /* Generic messages */
    required: "This field cannot be blank.",
    maxLength: "This value must be no more than ",
    minLength: "This value must be no less than ",
    invalid: "This value appears to be invalid.",
    invalidDate: "This value is not a valid date.",
    invalidTime: "This value is not a valid time.",
    invalidLocation: "Please select a location from the drop-down list of options.",
    fileRequired: "You must select a file to upload.",
    reportingDateRangeNotSelected: "You must select a date range to run this report.",

    /* Server messages */
    serverErrorPrefix: "Back-End: ",
    severForbiddenError: "You do not have the necessary authorization to make this request.",
    serverNotFoundError: "This data either does not exist or you are not authorized to view it.",
    serverRequestError: "The server is not responding. Please check your internet connection or try again later.",
    serverGenericError: "Something went wrong while attempting to process your request. Please try again later.",

    /* Custom messages */
    CompanyEmployeeNotVerified: "Company Employee is not verified"
};

export const SuccessMessages = {
    // Organization
    organizationCreated: "The organization has been created successfully.",
    organizationUpdated: "The organization has been updated successfully.",
    organizationDeleted: "The organization has been deleted successfully.",

    // Emergency
    emergencyBroadcasted: "Emergency message broadcasted to all people.",

    // Establishment
    establishmentCreated: "The establishment has been created successfully.",
    establishmentUpdated: "The establishment has been updated successfully.",
    establishmentDeleted: "The establishment has been deleted successfully.",

    // Building
    buildingCreated: "The building has been created successfully.",
    buildingUpdated: "The building has been updated successfully.",
    buildingDeleted: "The building has been deleted successfully.",

    // Unit
    unitCreated: "The unit has been created successfully.",
    unitUpdated: "The unit has been updated successfully.",
    unitDeleted: "The unit has been deleted successfully.",

    // Company Unit
    unitLinked: "The units have been linked successfully.",
    unitLinkingUpdated: "The units have been updated successfully.",

    // Company Employee Admin
    companyEmployeeAdminLinked: "The company admin has been linked successfully.",
    companyEmployeeAdminDelinked: "The company admin has been delinked successfully.",

    // Company Employee
    companyEmployeeLinked: "The company employee has been linked successfully.",
    companyEmployeeDeleted: "The company employee has been deleted successfully.",

    //Company
    companyCreated: "The company has been created successfully.",
    companyDeleted: "The company has been deleted successfully.",
    companyUpdated: "The company has been updated successfully.",

    //Amenity
    amenityCreated: "The amenity has been created successfully.",
    amenityDeleted: "The amenity has been deleted successfully.",
    amenityUpdated: "The amenity has been updated successfully.",

    //Bill
    billCreated: "The bill has been created successfully.",
    billDeleted: "The bill has been deleted successfully.",
    billUpdated: "The bill has been updated successfully.",

    //Amenity Exclusion
    amenityExclusionCreated: "The amenity exclusion has been created successfully.",
    amenityExclusionDeleted: "The amenity exclusion has been deleted successfully.",
    amenityExclusionUpdated: "The amenity exclusion has been updated successfully.",

    //Amenity Slot
    amenitySlotCreated: "The amenity slot has been created successfully.",
    amenitySlotDeleted: "The amenity slot has been deleted successfully.",
    amenitySlotUpdated: "The amenity slot has been updated successfully.",

    // Service Category
    serviceCategoryCreated: "The service category has been created successfully.",
    serviceCategoryUpdated: "The service category has been updated successfully.",
    serviceCategoryDeleted: "The service category has been deleted successfully.",

    // Service Sub-Category
    serviceSubCategoryCreated: "The service sub-category has been created successfully.",
    serviceSubCategoryUpdated: "The service sub-category has been updated successfully.",
    serviceSubCategoryDeleted: "The service sub-category has been deleted successfully.",

    // Service
    serviceCreated: "The service has been created successfully.",
    serviceUpdated: "The service has been updated successfully.",
    serviceDeleted: "The service has been deleted successfully.",

    // Service Slot
    serviceSlotCreated: "The service slot has been created successfully.",
    serviceSlotUpdated: "The service slot has been updated successfully.",
    serviceSlotDeleted: "The service slot has been deleted successfully.",

    // Service Exclusion
    serviceExclusionCreated: "The service exclusion has been created successfully.",
    serviceExclusionUpdated: "The service exclusion has been updated successfully.",
    serviceExclusionDeleted: "The service exclusion has been deleted successfully.",

    // Employee Category
    employeeCategoryCreated: "The employee category has been created successfully.",
    employeeCategoryUpdated: "The employee category has been updated successfully.",
    employeeCategoryDeleted: "The employee category has been deleted successfully.",

    // User
    userCreated: "The user has been created successfully.",
    userUpdated: "The user has been updated successfully.",
    userDeleted: "The user has been deleted successfully.",

    // Many-To-Many
    mappingSuccessful: "The records have been updated successfully.",
    mappingSuccessfullyDeleted: "The record has been deleted successfully.",

    // NDA
    NDACreated: "NDA has been created successfully",
    NDAActivated: "NDA has been activated successfully",
    NDADeactivated: "NDA has been deactivated successfully",

    // Visitor
    VisitorDetailsUpdated: "Visitor details have been updated successfully",
    VisitorCreated: "Visitor has been created successfully",
    CompanyEmployeeVerified: "Company Employee has been verified successfully"
};

/* Util method to convert file into base64 */
export const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

/* Util method to find if role is linked */
export const isLinked = body => {
    if (
        !body.is_organization_administrator_linked &&
        !body.is_establishment_admin_linked &&
        !body.is_employee_linked &&
        !body.is_security_guard_linked &&
        !body.is_company_employee_admin_linked &&
        !body.is_company_employee_linked
    ) {
        return false;
    }
    return true;
};

export const convertToUTC = date => {
    const [hours, minutes, seconds] = date.split(":").map(Number);
    const now = new Date();
    now.setHours(hours);
    now.setMinutes(minutes);
    now.setSeconds(seconds);

    const utcTime = now.toISOString();

    return utcTime;
};

/* Util method to extract time from date object */
export const extractTime = date => {
    const hour = date.getHours();
    const minute = date.getMinutes();

    const formattedHour = hour < 10 ? "0" + hour : hour;
    const formattedMinute = minute < 10 ? "0" + minute : minute;

    return formattedHour + ":" + formattedMinute;
};

/* Util method to find an array intersection */
export const findArrayIntersection = (arr1, arr2) => {
    const set = new Set(arr1);
    return arr2.filter(item => set.has(item));
};

/* Util method to change the body attribute */
export const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value);
};

/* Utility method to extract location properties from a Google maps object */
export const getLocationFromGoogleMapsObject = addressComponents => {
    const location = {
        city: "",
        state: ""
    };

    if (Array.isArray(addressComponents) && addressComponents.length > 0) {
        addressComponents.forEach((item, index) => {
            // Get city
            if (item.types.includes("locality")) {
                location.city = item.long_name;
            }
            // Get state
            if (item.types.includes("administrative_area_level_1")) {
                location.state = item.long_name;
            }
        });
    }
    return location;
};

/* Utility method to convert a date object into the expected format  */
export const formatDate = date => {
    let dateObject = new Date(date);
    let month = (dateObject.getMonth() + 1).toString();
    let day = dateObject.getDate().toString();
    let year = dateObject.getFullYear().toString();

    if (month.length < 2) {
        month = "0" + month;
    }
    if (day.length < 2) {
        day = "0" + day;
    }
    return [year, month, day].join("-");
};

/* Utility method to convert a date object into a human readable string  */
export const convertToReadableDate = date => {
    return new Date(date).toDateString();
};

/* Utility method to convert a time object into a human readable string  */
export const convertReadableTime = time => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
};

/* Utility method to get all days of week */
export const getDaysOfWeek = () => {
    return [
        {
            label: "Monday",
            value: 1
        },
        {
            label: "Tuesday",
            value: 2
        },
        {
            label: "Wednesday",
            value: 3
        },
        {
            label: "Thursday",
            value: 4
        },
        {
            label: "Friday",
            value: 5
        },
        {
            label: "Saturday",
            value: 6
        },
        {
            label: "Sunday",
            value: 7
        }
    ];
};

/* Utility method to get a particular day of week */
export const getDayOfWeek = dayIndex => {
    let day;
    switch (dayIndex) {
        case 1:
            day = {
                label: "Monday",
                value: 1
            };
            break;
        case 2:
            day = {
                label: "Tuesday",
                value: 2
            };
            break;
        case 3:
            day = day = {
                label: "Wednesday",
                value: 3
            };
            break;
        case 4:
            day = day = {
                label: "Thursday",
                value: 4
            };
            break;
        case 5:
            day = day = {
                label: "Friday",
                value: 5
            };
            break;
        case 6:
            day = day = {
                label: "Saturday",
                value: 6
            };
            break;
        case 7:
            day = day = {
                label: "Sunday",
                value: 7
            };
            break;
        default:
            break;
    }
    return day;
};

/* Utility method to get all slots */
export const getDaySlots = () => {
    return [
        {
            label: "00:00",
            value: "00:00:00"
        },
        {
            label: "00:30",
            value: "00:30:00"
        },
        {
            label: "01:00",
            value: "01:00:00"
        },
        {
            label: "01:30",
            value: "01:30:00"
        },
        {
            label: "02:00",
            value: "02:00:00"
        },
        {
            label: "02:30",
            value: "02:30:00"
        },
        {
            label: "03:00",
            value: "03:00:00"
        },
        {
            label: "03:30",
            value: "03:30:00"
        },
        {
            label: "04:00",
            value: "04:00:00"
        },
        {
            label: "04:30",
            value: "04:30:00"
        },
        {
            label: "05:00",
            value: "05:00:00"
        },
        {
            label: "05:30",
            value: "05:30:00"
        },
        {
            label: "06:00",
            value: "06:00:00"
        },
        {
            label: "06:30",
            value: "06:30:00"
        },
        {
            label: "07:00",
            value: "07:00:00"
        },
        {
            label: "07:30",
            value: "07:30:00"
        },
        {
            label: "08:00",
            value: "08:00:00"
        },
        {
            label: "08:30",
            value: "08:30:00"
        },
        {
            label: "09:00",
            value: "09:00:00"
        },
        {
            label: "09:30",
            value: "09:30:00"
        },
        {
            label: "10:00",
            value: "10:00:00"
        },
        {
            label: "10:30",
            value: "10:30:00"
        },
        {
            label: "11:00",
            value: "11:00:00"
        },
        {
            label: "11:30",
            value: "11:30:00"
        },
        {
            label: "12:00",
            value: "12:00:00"
        },
        {
            label: "12:30",
            value: "12:30:00"
        },
        {
            label: "13:00",
            value: "13:00:00"
        },
        {
            label: "13:30",
            value: "13:30:00"
        },
        {
            label: "14:00",
            value: "14:00:00"
        },
        {
            label: "14:30",
            value: "14:30:00"
        },
        {
            label: "15:00",
            value: "15:00:00"
        },
        {
            label: "15:30",
            value: "15:30:00"
        },
        {
            label: "16:00",
            value: "16:00:00"
        },
        {
            label: "16:30",
            value: "16:30:00"
        },
        {
            label: "17:00",
            value: "17:00:00"
        },
        {
            label: "17:30",
            value: "17:30:00"
        },
        {
            label: "18:00",
            value: "18:00:00"
        },
        {
            label: "18:30",
            value: "18:30:00"
        },
        {
            label: "19:00",
            value: "19:00:00"
        },
        {
            label: "19:30",
            value: "19:30:00"
        },
        {
            label: "20:00",
            value: "20:00:00"
        },
        {
            label: "20:30",
            value: "20:30:00"
        },
        {
            label: "21:00",
            value: "21:00:00"
        },
        {
            label: "21:30",
            value: "21:30:00"
        },
        {
            label: "22:00",
            value: "22:00:00"
        },
        {
            label: "22:30",
            value: "22:30:00"
        },
        {
            label: "23:00",
            value: "23:00:00"
        },
        {
            label: "23:30",
            value: "23:30:00"
        }
    ];
};


export const  formatReadableTime = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    const parts = [];
    // Append hours if present
    if (hours > 0) {
        parts.push(`${hours} hr${hours > 1 ? 's' : ''}`);
    }

    // Append minutes if present
    if (minutes > 0) {
        parts.push(`${minutes} min${minutes > 1 ? 's' : ''}`);
    }

    // Append seconds if present
    if (seconds > 0 || parts.length === 0) { // Show seconds if it's 0:00:00
        parts.push(`${seconds} sec${seconds > 1 ? 's' : ''}`);
    }

    return parts.join(', ');
}

